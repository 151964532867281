




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { mixins } from 'vue-class-component';
import TasqListMixin from '@/components/tasqsCommon/TasqListMixin';
import {
  Component,
} from 'vue-property-decorator';

@Component
export default class TasqsVerticalList extends mixins(TasqListMixin) {
  requestedMinDate: any = null;

  requestedMaxDate : any = null;

  showAddNewItemDropdown = false;

  workTicketFilter: any = []

  async created() {
    await this.prepareTasqList();
  }

  addTasq(type) {
    this.showAddNewItemDropdown = false;
    if (type === 'addTasq') {
      this.$emit('add-tasq');
    } else {
      this.$emit('add-work-ticket');
    }
  }

  closeAddItemPopup() {
    this.showAddNewItemDropdown = false;
  }
}
